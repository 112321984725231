<template>
  <div class="d-flex flex-column fill-height">
    <div id="map-reporte-cameras" class="map" ref="map">
    </div>
    <div>
      <v-simple-table height="300" dense fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Camara
              </th>
              <th class="text-left">
                Tiempo Disponible
              </th>
              <th class="text-left">
                Porcentaje de Disponibilidad
              </th>
              <th class="text-left">
                Tiempo de Salida
              </th>
            </tr>
          </thead>
            <tbody>
              <tr
                style="cursor: pointer;"
                :class="{ active: item.cameraId === selectedId }"
                v-for="(item, index) in summary"
                :key="'sum' + index"
              >
                <td>{{ getCameraName(item.cameraId) }}</td>
                <td>{{ calcularTiempoSalida(item.secondsAvailability) }}</td>
                <td>{{ item.availabilityPercent }}</td>
                <td>{{ calcularTiempoSalida(item.secondsOutage) }}</td>
              </tr>
            </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import _ from 'underscore'
import Dialog from '@/components/dialog'
import Form from '@/components/form'
import ApiService from '@/util/api.service'
import CameraStore from '@/components/map/store/cameraStore'
import { periods } from '@/util'

const ol = window.ol

export default {
  name: 'CamerasAvailabilityReport',
  props: {
    cameras: Array,
    groups: Array,
  },
  data() {
    return {
      map: null,
      initZoom: 12,
      mapMode: 'day',
      itemsTable: [],
      summary: [],
      camerasObj: {},
      selected: null,
      selectedId: null,
      filtro: {
        from: new Date(),
        fromHour: '00:00',
        to: new Date(),
        toHour: '23:59',
      },
      filtersForm: {
        cameraId: {
          label: 'Camaras',
          type: Array,
          options: this.cameras,
          multiple: true,
          optional: true
        },
        'cameraId.$': {
          type: String,
          blackbox: true,
        },
        groupId: {
          label: 'Grupos',
          type: Array,
          options: this.groups,
          multiple: true,
          optional: true
        },
        'groupId.$': {
          type: String,
          blackbox: true,
        },
        periodo: {
          label: 'Periodo',
          type: String,
          options: periods,
          onChange: (val, fields) => {
            fields.from_separator.setVisibility(val === 'custom')
            fields.from.setVisibility(val === 'custom')
            fields.fromHour.setVisibility(val === 'custom')
            fields.to_separator.setVisibility(val === 'custom')
            fields.to.setVisibility(val === 'custom')
            fields.toHour.setVisibility(val === 'custom')
          }
        },
        from_separator: {
          label: 'Desde',
          type: String,
          separator: true,
          optional: true
        },
        from: {
          label: 'Fecha',
          type: Date,
          visibility: false
        },
        fromHour: {
          label: 'Hora (HH:mm)',
          type: String,
          visibility: false,
          time: true
        },
        to_separator: {
          label: 'Hasta',
          type: String,
          separator: true,
          optional: true
        },
        to: {
          label: 'Fecha',
          type: Date,
          visibility: false
        },
        toHour: {
          label: 'Hora (HH:mm)',
          type: String,
          visibility: false,
          time: true
        }
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    // keymap() {
    //   return {
    //     up: () => {
    //       if (this.selectedId > 0) {
    //         this.selectCoordinate(this.itemsTable[this.selectedId - 1])
    //       }
    //     },
    //     down: () => {
    //       if (this.selectedId != null && this.selectedId + 1 <= this.itemsTable.length) {
    //         this.selectCoordinate(this.itemsTable[this.selectedId + 1])
    //       }
    //     }
    //   }
    // }
  },
  created() {
    this.camerasObj = this.cameras.toObject('id')
  },
  mounted() {
    this.sourceCameras = new ol.source.Vector()

    this.camerasLayer = new window.ol.layer.Vector({
      source: this.sourceCameras
    })

    this.camerasStore = new CameraStore(this, this.sourceCameras)
    this.map = new ol.layer.Tile({
      source: new ol.source.XYZ({
        url: '//mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}'
      })
    })

    this.mapView = new ol.View({
      center: this.getMapCenter || ol.proj.fromLonLat([
        this.getUser.longitude, this.getUser.latitude
      ]),
      zoom: this.initZoom
    })

    this.olmap = new ol.Map({
      target: 'map-reporte-cameras',
      layers: [
        this.map,
        this.camerasLayer
      ],
      view: this.mapView
    })
  },
  methods: {
    async load() {
      window.VMA.loading(true)
      const data = {
        cameraId: this.filtro.cameraId,
        groupId: this.filtro.groupId,
        ...this.period
      }
      try {
        const _itemsTable = await ApiService({
          url: '/reports/cameras/availability',
          method: 'get',
          params: data
        })
        this.itemsTable = _itemsTable
      } catch (e) {
        console.error(e)
        window.VMA.showError({ title: 'Ocurrió un error al cargar los datos' })
      } finally {
        window.VMA.loading(false)
      }
    },
    getCameraName(id) {
      if (this.camerasObj[id]) {
        return this.camerasObj[id].name
      }
      return ''
    },
    selectCamera(selectedId) {
      if (selectedId === this.selectedId) {
        this.selectedId = null
      } else {
        this.selectedId = selectedId
        this.centerCamera(selectedId)
      }
    },
    centerCamera(cameraId) {
      this.camerasStore.center(cameraId)
    },
    calcularTiempoSalida(durationMs) {
      if (!durationMs) {
        return 0
      }
      const segundos = (durationMs / 1000).toFixed(0)
      if (parseInt(segundos) <= 59) {
        return segundos + ' ' + (parseInt(segundos) === 1 ? 'segundo' : 'segundos')
      }
      const minutos = (parseInt(segundos) / 60).toFixed(0)
      if (parseInt(minutos) <= 119) {
        return minutos + ' ' + (parseInt(minutos) === 1 ? 'minuto' : 'minutos')
      }
      return parseInt(minutos / 60).toFixed(0) + ' horas'
    },
    filtrar() {
      const form = new Form({
        schema: this.filtersForm,
        item: this.filtro
      })
      const dialog = new Dialog({
        title: 'Filtrar rutas',
        actions: [{
          help: 'Filtrar',
          icon: 'mdi-filter',
          color: 'secondary',
          action: async () => {
            if (form.hasErrors()) {
              return
            }
            const item = form.getItem()
            this.filtro = item
            if (item.periodo !== 'custom') {
              this.period = form.fields.periodo.getOption().getPeriod()
            } else {
              this.period = form.fields.periodo.getOption().getPeriod({
                ..._.pick(item, 'from', 'to', 'fromHour', 'toHour')
              })
            }
            this.load()
            dialog.close()
          }
        }]
      })
      dialog.open()
      dialog.append(form)
    }
  }
}

</script>

<style lang="scss">
  .map {
    height: 100%;
    width: 100%;
  }

  .abst {
    position: absolute;
    top: 64px;
    bottom: 0;
    left: 0;
    width: 100%;
  }
</style>
